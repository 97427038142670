<template>
  <div class="share-button-group">
    <BaseButton
      :href="facebookURL"
      target="_blank"
      icon="facebook"
      class="share-button"
      @click="toFacebook"
      >{{ $l10n('facebook') }}</BaseButton
    >

    <BaseButton
      :href="twitterURL"
      target="_blank"
      icon="twitter"
      class="share-button"
      @click="toTwitter"
      >{{ $l10n('twitter') }}</BaseButton
    >
  </div>
</template>

<script>
import { mapState } from 'vuex';
const isMobile = typeof window.orientation !== 'undefined';

export default {
  props: {
    link: {
      type: String,
      default: 'index',
    },
  },
  computed: {
    ...mapState(['baseURL']),

    url() {
      return (
        window.location.origin + this.$store.state.baseURL + this.link + '.html'
      );
    },

    facebookURL() {
      return 'https://www.facebook.com/sharer.php?u=' + this.url;
    },

    twitterURL() {
      return 'https://twitter.com/intent/tweet?url=' + this.url;
    },
  },
  methods: {
    openShare(url, width, height) {
      let options = '';

      if (!isMobile) {
        url += '&display=popup';
        options = `toolbar=0,status=0,resizeable=1,width=${width},height=${height}`;
      }

      window.open(url, 'share', options);
    },
    toFacebook(event) {
      if (!isMobile) {
        event.preventDefault();
        this.openShare(this.facebookURL, 626, 436);
      }
    },
    toTwitter(event) {
      if (!isMobile) {
        event.preventDefault();
        this.openShare(this.twitterURL, 550, 300);
      }
    },
  },
};
</script>

// a short haiku outlining the fact that this was indeed intentional:
// a word to the wise
// the things in this file defile
// basic decency
import { injectGlobal } from 'vue-styled-components';
import get from 'better-theme-get';
import theme from './theme';

// with this injectGlobal function, we cannot access the theme automagically, so we leverage better theme gets hoc method to statically get the items
const tget = s =>
  get(s)({
    theme,
  });

const global = injectGlobal`
    .demosurvey__buttons .button {
        border-color: ${tget('colors.buttonBorder')} !important;
        &:hover {
            color: ${tget('colors.buttonBorder')} !important;
        }
    }

    .demosurvey__buttons .button.is-selected {
        background: ${tget('colors.buttonBorder')} !important;
        &:hover {
            color: ${tget('colors.text')} !important;
        }
    }

    .byline {
        color: ${tget('colors.text')} !important;
        &.button.is-quaternary {
          background: linear-gradient(
            to right,
            ${tget('colors.text')} 50%,
            ${tget('colors.buttonBackground')} 50%
          );
          background-size: 201% 100%;
          background-position: right center;
          &:hover,
          &:focus {
            color: ${tget('colors.buttonText')} !important;
            background-position: left center;
          }
        }
    }

    a.button.is-quaternary {
        color: ${tget('colors.text')} !important;
        background: linear-gradient(
        to right,
        ${tget('colors.text')} 50%,
        ${tget('colors.buttonBackground')} 50%
        );
        background-size: 201% 100%;
        background-position: right center;
        &:hover,
        &:focus {
        color: ${tget('colors.buttonText')} !important;
        background-position: left center;
        }
    }
    .draggable-question__help :nth-child(2) {
        color: ${tget('colors.text')} !important;
    }
    .modal__frame {
        background-color: ${tget('colors.modalBg')} !important;
        /* h3 {
            color: ${tget('colors.buttonText')} !important;
        } */
    }
    // .button.is-primary.is-large {
    //     font-family: Corben, sans-serif !important;
    // }

    .homescreen__title {
        // font-size: 5vh !important;
    }
    p {
        font-family: Jost, sans-serif !important;
        font-style: normal !important;
    }
    button {
        font-family: Jost, sans-serif !important;
    }
    .button.is-primary .button__inner:before {
        background: ${tget('colors.buttonHover')} !important;
    }
    .button.is-primary, .button.is-primary.is-large, .button.is-primary.is-rounded, .button.is-primary.is-large, .button.is-primary.is-circular {
        border-color: ${tget('colors.buttonBorder')} !important;  
        color: ${tget('colors.buttonBorder')} !important;  
    }
    .is-plain, .is-link {
        color: ${tget('colors.text')} !important;  
    }
    /* .draggable-hand__handle__label.is-plain {
        color: ${tget('colors.buttonText')} !important;  
    } */

    .draggable-hand__holster.is-plain {
        color: ${tget('colors.text')} !important;  
    }

    .progress-star.is-filled .icon__fill {
        fill: ${tget('colors.thumbUpBgColor')} !important;
    }
    .progress-star {
        stroke: ${tget('colors.progressStarStrokeColor')} !important;
    }

    .button.is-tertiary, .button.is-secondary {
        border-color: ${tget('colors.buttonBorder')} !important;  
        color: ${tget('colors.text')} !important;  
        background: linear-gradient(
          to right,
          ${tget('colors.text')} 50%,
          ${tget('colors.buttonBackground')} 50%
        ) !important;
        background-size: 201% 100% !important;
        background-position: right center !important;
        &:hover, &:focus {
          color: ${tget('colors.buttonText')} !important;
          background-position: left center !important;
          svg {
            color: ${tget('colors.buttonText')} !important;
          }
        }
      }

    .draggable-hand.left {
       color: ${tget('colors.trueThumbStrokeColor')}; 
       & path.hand__fill {
            fill: ${tget('colors.trueThumbBgColor')} !important; 
       }
       g > .hand__outline {
            fill: ${tget('colors.trueThumbStrokeColor')} !important; 
       }
       .draggable-hand__arm__stroke {
            stroke: ${tget('colors.trueThumbStrokeColor')} !important; 
       }
       .draggable-hand__arm__fill {
            stroke: ${tget('colors.trueThumbBgColor')} !important; 
       }
    }
    .draggable-hand.right {
        color: ${tget('colors.falseThumbStrokeColor')}; 
        & path.hand__fill {
             fill: ${tget('colors.falseThumbBgColor')} !important; 
        }
        g > .hand__outline {
             fill: ${tget('colors.falseThumbStrokeColor')} !important; 
        }
        .draggable-hand__arm__stroke {
             stroke: ${tget('colors.falseThumbStrokeColor')} !important; 
        }
        .draggable-hand__arm__fill {
             stroke: ${tget('colors.falseThumbBgColor')} !important; 
        }
     }

    .modal {
       background: ${tget('colors.modalBackground')} !important; 
    }

    body {
        overflow: hidden;
        font-family: Jost, sans-serif;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.3;
        color: ${tget('colors.text')};
        background: ${tget('colors.background')};
    
        ${tget('mediaQueries.desktopHeight')} {
            font-size: 1.8rem;
        }
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.2;
        margin: 0 0 0.5em;
    }
    
    p {
        margin: 0 0 1em;
    }
    
    p, h1, h2, h3, h4, h5, h6, li, span {
        color: ${tget('colors.text')};
    }

    .modal__body p {
        color: ${tget('colors.text')};
    }
    .modal__body h2 {
        color: ${tget('colors.text')};
    }

    circle, text {
        stroke: ${tget('colors.text')} !important;
        color: ${tget('colors.text')} !important;
    }

    input[type=radio] {
        background: ${tget('colors.buttonBackground')} !important;
    }

    a {
        display: inline-block;
        line-height: 1.2;
        color: inherit;
        //text-decoration: none;
    
        background-image: linear-gradient(
        to right,
        rgba($color-purple, 0) 0,
        rgba($color-purple, 0) 50%,
        rgba($color-purple, 1) 50%,
        rgba($color-purple, 1) 100%
        );
        background-position: right bottom;
        background-repeat: repeat-x;
        background-size: 4px 2px;
    
        &:hover {
        background-size: 200% 2px;
        }
    
        &:focus {
        color: $color-white;
        background: $color-purple;
        }
    
        &[data-with-image] {
        display: inline-block;
        background: none;
        }
    }  
    .star, .star__label {
        // color:${tget('colors.buttonBackground')} !important;
    }
`;

export default global;

<template>
  <BaseScreen
    :name="id"
    :class="{
      gamescreen: true,
      'with-hands': useHands,
    }"
    @toggle="toggleActive"
  >
    <Transition mode="out-in">
      <div
        v-if="hasResult"
        ref="resultPane"
        key="result"
        tabindex="0"
        class="gamescreen__inner gamescreen__result"
      >
        <div class="gamescreen__header">
          <h2 v-content="resultTitle" class="gamescreen__title" />

          <div
            v-content="result ? correct_text : incorrect_text"
            class="gamescreen__details"
          />

          <BaseButton
            name="read-more"
            :question="name"
            is-tertiary
            @click="showModal(moreModalId)"
            >{{ $l10n('read-more') }}</BaseButton
          >
        </div>

        <div
          :class="{
            gamescreen__graphic: true,
            'is-correct': result === true,
            'is-incorrect': result === false,
            'is-timeout': result === 'timeout',
          }"
        />

        <BaseButton
          name="next"
          :question="name"
          icon="next"
          is-primary
          is-circular
          class="gamescreen__continue"
          data-cy="continue"
          @click="$emit('done')"
          >{{ $l10n('continue') }}</BaseButton
        >
      </div>
      <div
        v-else
        ref="questionPane"
        key="question"
        class="gamescreen__inner gamescreen__question"
      >
        <div class="gamescreen__header">
          <h2 v-content="title" class="gamescreen__title" />
          <div v-content="text" class="gamescreen__details" />
        </div>

        <Component
          :is="uiComponent"
          class="gamescreen__body"
          :is-active="isActive"
          :is-repeat="isRepeat"
          v-bind="control"
          @answer="logAnswer"
        />

        <BaseButton
          v-if="skippable"
          class="gamescreen__skip"
          data-cy="skip"
          is-plain
          @click="logAnswer('abstain')"
          >{{ $l10n('skip') }}</BaseButton
        >
      </div>
    </Transition>

    <Portal :to="id">
      <BaseModal
        v-if="hasResult"
        class="readmore"
        :name="moreModalId"
        is-yellow
      >
        <div class="readmore__header">
          <h2 class="gamescreen__title">{{ resultTitle }}</h2>

          <div
            v-content="result === true ? correct_text : incorrect_text"
            class="gamescreen__details"
          />
        </div>

        <div
          v-if="read_more_text"
          class="readmore__body"
          v-html="read_more_text"
        />

        <BaseButton
          name="correction"
          :question="name"
          is-tertiary
          @click="showModal(correctionModalId)"
          >{{ $l10n('offer-correction') }}</BaseButton
        >
      </BaseModal>
      <BaseModal
        v-if="hasResult"
        class="correction"
        :name="correctionModalId"
        is-yellow
      >
        <h2 class="gamescreen__title">{{ $l10n('submit-correction') }}</h2>

        <form class="correction-form" @submit.prevent="submitCorrection">
          <label
            class="correction-form__label"
            :for="`${id}-correction-source`"
            >{{ $l10n('correction-source') }}</label
          >
          <input
            :id="`${id}-correction-source`"
            ref="correctionSource"
            class="correction-form__input"
            type="text"
            required
          />

          <label
            class="correction-form__label"
            :for="`${id}-correction-comments`"
            >{{ $l10n('correction-comments') }}</label
          >
          <textarea
            :id="`${id}-correction-comments`"
            ref="correctionComments"
            class="correction-form__input"
            rows="5"
            maxlength="500"
            required
          />

          <div class="correction-form__actions">
            <BaseButton
              is-secondary
              type="reset"
              @click="hideModal(correctionModalId)"
              >{{ $l10n('cancel') }}</BaseButton
            >
            <BaseButton is-primary is-rounded type="submit">{{
              $l10n('submit')
            }}</BaseButton>
          </div>
        </form>
      </BaseModal>
    </Portal>
  </BaseScreen>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

const NORMAL_QUESTION_TYPES = {
  binary: 'ButtonsQuestion',
  quaternary: 'ButtonsQuestion',
  slider: 'SliderQuestion',
};

const HAND_QUESTION_TYPES = {
  binary: 'BinaryDraggableQuestion',
  quaternary: 'QuaternaryDraggableQuestion',
  slider: 'HandSliderQuestion',
};

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    correct_text: {
      type: String,
      default: '',
    },
    incorrect_text: {
      type: String,
      default: '',
    },
    read_more_text: {
      type: String,
      default: '',
    },
    source_url: {
      type: String,
      default: '',
    },
    control: {
      type: Object,
      required: true,
    },
    answer: {
      type: String,
      default: null,
    },
    isRepeat: Boolean,
    affectsScore: {
      type: Boolean,
      default: true,
    },
    skippable: Boolean,
    useHands: Boolean,
  },
  data() {
    const uiComponent = (
      this.useHands ? HAND_QUESTION_TYPES : NORMAL_QUESTION_TYPES
    )[this.control.type];

    return {
      startTime: Date.now(),
      uiComponent,
      moreModalId: this.id + '-more',
      correctionModalId: this.id + '-correction',
    };
  },
  computed: {
    ...mapState(['currentScreen']),
    ...mapGetters(['getAnswer']),

    isActive() {
      return this.currentScreen === this.id;
    },

    hasAnswer() {
      return this.answer !== null;
    },

    result() {
      // Get the stored answer for the question
      const answer = this.getAnswer(this.id);

      return this.getResult(answer);
    },

    resultTitle() {
      if (this.result === true) {
        return this.$l10n('correct');
      } else if (this.result === false) {
        return this.$l10n('incorrect');
      }

      return this.$l10n('timed-out');
    },

    hasResult() {
      return this.result !== null;
    },
  },
  watch: {
    isActive() {
      this.startTime = Date.now();
    },
  },
  updated() {
    // basically, read out the content that appears on all pages sans the first/home
    if (this.hasResult) {
      setTimeout(() => {
        this.$refs.resultPane.focus();
      }, 2000);
    } else {
      setTimeout(() => {
        this.$refs.questionPane.focus();
      }, 1000);
    }
  },
  methods: {
    ...mapMutations(['saveAnswer', 'changeScore', 'hideModal']),

    toggleActive(isActive) {
      if (isActive) {
        this.startTime = Date.now();
      }
    },

    getResult(answer) {
      // If timed out, return answer as result
      if (answer === 'timeout') {
        return answer;
      }

      // If an answer is required and provided, use the result
      if (this.hasAnswer && answer !== null) {
        // Treat an empty answer as an empty result
        if (answer === '') {
          return answer;
        }
        return answer === this.answer;
      }

      return null;
    },

    logAnswer(answer) {
      const result = this.getResult(answer);
      const isCorrect = result === true;
      const timeSpent = Date.now() - this.startTime;

      this.$emit('answer', result);

      this.saveAnswer({
        question: this.id,
        answer,
        isCorrect,
      });

      this.$log.answer({
        question_type: this.isRepeat ? 'repeat' : this.control.name,
        question_name: this.name,
        answer_text: answer,
        duration_in_seconds: Math.round(timeSpent / 1000),
        result: this.hasAnswer ? (isCorrect ? 'correct' : 'incorrect') : '',
      });

      // If no result, just advance
      if (!this.hasResult) {
        this.$emit('done');
      } else if (this.affectsScore) {
        this.changeScore(isCorrect);

        setTimeout(
          () => this.$sounds.play(isCorrect ? 'correct' : 'incorrect'),
          1500
        );
      }
    },

    submitCorrection() {
      this.$log.correction({
        question_name: this.name,
        source_url: this.$refs.correctionSource.value,
        comments: this.$refs.correctionComments.value,
      });

      this.hideModal(this.correctionModalId);
    },
  },
};
</script>

<template functional>
  <svg
    :class="{
      hand: true,
      ['is-color-' + props.color]: true,
      'is-open': props.isOpen,
    }"
    viewBox="0 0 150 180"
  >
    <g class="hand--closed">
      <path
        class="hand__fill"
        d="M2.7,125.5c0,0-6.3-19.1,4.9-31.9c8.3-9.5,17.2-14.5,22.1-19.2c5-4.7,9.7-11.2,16.9-17.3C59.7,46,69,49.3,78.2,50.8c11.8,5.4,29,11.2,42.8,15.9c8.4,2.1,15.2,8.3,17.9,16.6c6.4,1.2,9.3,7,9,14.5c-0.1,4.4-1.9,8.5-5,11.7c1.9,3.8,2.3,8,1.3,12.1c-1.9,7.3-5.9,9.7-5.9,9.7s7.1,12.7-4.8,24.4c0,0,2.2,16.6-8.3,21.4c-9.4,4.3-21.7,0.1-29.3-5.8c0,0-8.5,8.5-25.9,7.4s-35-12-41.2-22.1c0,0-5-7.3-6.9-13.2C18,141.8,8.8,137.9,2.7,125.5z"
      />
      <g class="hand__outlines">
        <path
          class="hand__outline"
          d="M66.4,77.5c3.1,13.4-0.7,26-7,37.7c-0.3,0.5-0.1,1.2,0.4,1.4c0.5,0.3,1.2,0.1,1.4-0.4c6.5-12.1,10.4-25.3,7.2-39.2c-0.1-0.6-0.7-0.9-1.3-0.8C66.6,76.4,66.3,77,66.4,77.5z"
        />
        <path
          class="hand__outline"
          d="M105.4,75.7c-1.3,1.6-4.5,6.8-4.9,8.7c-0.1,0.6,0.2,1.1,0.8,1.3c0.6,0.1,1.1-0.2,1.3-0.8c0.4-1.8,3.3-6.5,4.5-7.9c0.4-0.5,0.3-1.1-0.2-1.5C106.5,75.2,105.8,75.2,105.4,75.7z"
        />
        <path
          class="hand__outline"
          d="M143.2,108.4c4.6-4.2,5.8-11.3,2.9-17c-1.6-3.3-4.4-6-7.7-7.3c-0.5-0.2-0.8-0.8-0.6-1.4c0.2-0.5,0.8-0.8,1.4-0.6c3.9,1.6,7,4.6,8.8,8.3c3.3,6.5,0.6,16.3-3.5,19.7c-4.3,3.6-10.2,3.9-15.8,3.4c-8.1-0.7-15-3.5-19.1-6.5c-4.7-3.4-5.7-9.1-4.3-13.6c0.2-0.6,0.8-0.9,1.3-0.7c0.6,0.2,0.9,0.8,0.7,1.3c-1.2,3.8-0.4,7.7,3.6,11.3c3,2.1,10.3,5.3,17.9,6C134,111.8,139.6,111.8,143.2,108.4z M144,109.2L144,109.2z"
        />
        <path
          class="hand__outline"
          d="M138.3,130.6c6.2-4.6,7.7-13.5,4.8-20.9c-0.2-0.5,0-1.2,0.6-1.4l0,0c0.5-0.2,1.2,0,1.4,0.6l0,0c3.3,8.2,1.4,18.2-5.5,23.3l0,0l0,0l0,0c-5.5,4.2-12.6,4.7-19.2,3.6c-6.5-1.2-12-3.8-14.9-6.8c-7.3-7.6-3.7-18.1,4.4-23.6c0.5-0.3,1.1-0.2,1.5,0.3c0.3,0.5,0.2,1.1-0.3,1.5c-7,4.9-10.1,13.5-4,20.6c2.4,2.4,7.7,5,13.7,6.1C126.7,134.8,134.2,133.6,138.3,130.6z"
        />
        <path
          class="hand__outline"
          d="M133.9,154.5c6.4-4.7,7.8-15.5,4.8-22.5c-0.2-0.5,0-1.2,0.5-1.4s1.2,0,1.4,0.5c3.5,7.8,2.2,20.8-5.6,25.1c-5.6,3.2-17.6,3.2-25.7,0.4c-5.6-1.9-10-4.7-11.5-7.8c-3.8-8.2,0.3-17.6,8-21.4c0.5-0.3,1.2,0,1.4,0.5c0.3,0.5,0,1.2-0.5,1.4c-6.8,3.4-9.7,11.2-7.1,18.7c1.4,2.7,5.4,5.1,10.2,6.7C117.6,157.2,127.4,157.9,133.9,154.5z"
        />
        <path
          class="hand__outline"
          d="M97.4,170.4c3.2,3.7,9.7,5.8,15.5,6.9c3.8,0.7,7.3,0.8,9.8,0c11-3.5,11.4-13.4,10.6-21.8c-0.1-0.6,0.4-1.1,0.9-1.2c0.6-0.1,1.1,0.4,1.2,0.9c0.9,9.2,0.2,20.1-12.1,23.9c-2.7,0.9-6.7,0.8-10.9,0c-6.2-1.2-14.2-3.9-16.8-7.7c-5.2-7.6-4.6-17.6,2.4-23.4c0.5-0.4,1.1-0.3,1.5,0.1c0.4,0.5,0.3,1.1-0.1,1.5C93.4,154.9,92.8,165,97.4,170.4z"
        />
        <path
          class="hand__outline"
          d="M102.3,90.3c-3.2,4.7-4.2,10.5-2.7,16.1c0.2,0.6,0.7,0.9,1.3,0.8c0.6-0.2,0.9-0.7,0.8-1.3c-1.3-5-0.4-10.1,2.5-14.4c0.3-0.5,0.2-1.1-0.3-1.5C103.3,89.7,102.6,89.9,102.3,90.3z"
        />
        <path
          class="hand__outline"
          d="M100.4,110.1c-1.8,1.9-3,4.4-3.5,7c-0.8,3.1-0.3,6.5,1.5,9.1c0.3,0.5,1,0.6,1.5,0.3c0.5-0.3,0.6-1,0.3-1.5c-1.4-2.3-1.8-5-1.1-7.5c0.4-2.2,1.3-4.2,2.8-5.9c0.4-0.4,0.5-1.1,0.1-1.5C101.5,109.7,100.8,109.7,100.4,110.1z"
        />
        <path
          class="hand__outline"
          d="M96.4,131.4c-4.1,3.6-5.7,9.3-3.9,14.5c0.2,0.6,0.8,0.8,1.4,0.7c0.6-0.2,0.8-0.8,0.7-1.4c-1.5-4.4-0.2-9.1,3.2-12.2c0.4-0.4,0.5-1.1,0.1-1.5C97.4,131,96.7,131,96.4,131.4L96.4,131.4z"
        />
        <path
          class="hand__outline"
          d="M91.6,151.2c-3.4,4.5-5.4,11-2.1,16.4c0.3,0.5,1,0.7,1.5,0.4c0.5-0.3,0.7-1,0.4-1.5c-2.8-4.6-1-10.1,2-14c0.4-0.5,0.3-1.1-0.2-1.5C92.6,150.7,91.9,150.8,91.6,151.2z"
        />
        <path
          class="hand__outline"
          d="M140.3,91.9c1.4,2.9,1.2,6.8,0.4,9.8c-0.1,0.6,0.2,1.1,0.8,1.3c0.6,0.1,1.1-0.2,1.3-0.8c0.9-3.5,1.1-7.9-0.6-11.2c-0.3-0.5-0.9-0.7-1.4-0.5C140.3,90.7,140.1,91.3,140.3,91.9L140.3,91.9z"
        />
        <path
          class="hand__outline"
          d="M138.9,116.4c0.6,3,0.2,6.1-1.2,8.7c-0.3,0.5,0,1.2,0.5,1.4l0,0c0.5,0.3,1.2,0,1.4-0.5l0,0c1.6-3.1,2.1-6.7,1.4-10.1c-0.1-0.6-0.7-0.9-1.3-0.8C139.1,115.2,138.7,115.8,138.9,116.4z"
        />
        <path
          class="hand__outline"
          d="M135.3,139.1c0.2,2.9,0.3,5.9-1.2,8.3c-0.3,0.5-0.1,1.2,0.4,1.5c0.5,0.3,1.2,0.1,1.5-0.4c1.7-2.9,1.8-6.4,1.5-9.6c0-0.6-0.6-1-1.1-1C135.7,138,135.3,138.5,135.3,139.1z"
        />
        <path
          class="hand__outline"
          d="M128.4,161.9c-0.2,3-1.6,5.7-3.8,7.7c-0.4,0.4-0.5,1.1-0.1,1.5c0.4,0.4,1.1,0.5,1.5,0.1c2.6-2.4,4.2-5.6,4.5-9c0.1-0.6-0.4-1.1-0.9-1.2C129,160.8,128.5,161.3,128.4,161.9z"
        />
        <path
          class="hand__outline"
          d="M21.5,143c2.6,7.5,9.5,18.5,15.2,22.9c8.7,7,22.7,13,36.1,13.6c9,0.4,17.8-1.8,24.5-7.7c0.4-0.4,0.5-1.1,0.1-1.5c-0.4-0.4-1.1-0.5-1.5-0.1c-6.4,5.6-14.6,7.5-23,7.2c-12.9-0.5-26.4-6.4-34.9-13.1c-5.4-4.3-12-14.7-14.5-22c-0.2-0.6-0.8-0.9-1.4-0.7C21.6,141.9,21.3,142.5,21.5,143z"
        />
        <path
          class="hand__outline"
          d="M19.6,83.3C17.2,85.1,15,87,14.9,87c-7.5,6-9.2,11.5-11,16c-5.8,14.3,2.2,40.3,29,39.2c0.6,0,1.1,0.4,1.1,1c0,0.6-0.4,1.1-1,1.1c-28.8,1.3-37.2-26.7-31.1-42.1c1.9-4.8,3.8-10.6,11.7-16.9c0,0,2.3-1.9,4.7-3.7h0.1c9.5-6.3,15.5-13.5,21.5-19.5c9.1-9.3,18-15.9,38.6-12.8c2.5,0.5,19.4,8.9,29,12c11,3.6,22.7,4.8,31,17.3l0,0c2.6,4.5,1.8,10.1-1.1,14.8c-2.8,4.6-7.6,8.1-13,8.6c-9.6,0.8-18.3-6.6-24.8-13.8c-5,0-9.9-0.3-14.8-1c-5.3-0.8-10.6-2.2-13.7-4.5c-0.5-0.4-0.6-1-0.2-1.5c0.4-0.5,1-0.6,1.5-0.2c2.9,2.2,7.9,3.4,12.8,4.1c5,0.7,9.9,1,14.9,1c0.3,0,0.6,0.1,0.8,0.3c3.2,3.7,6.9,7,10.8,9.7c3.9,2.6,8.1,4.2,12.6,3.8c4.7-0.4,8.9-3.6,11.4-7.6c2.5-4,3.3-8.8,1-12.6c-7.8-12-19.3-13-29.9-16.5c-9.2-3-27-11.1-28.8-12c-19.7-2.9-28,3.4-36.6,12.2C35.4,69.7,29.3,77,19.6,83.3z"
        />
      </g>
    </g>
    <g class="hand--open">
      <path
        class="hand__fill"
        d="M3.2,126.6c0,0-6.1-18.6,4.8-31.1c8-9.2,16.7-14.1,21.5-18.7s10.2-7.9,11.4-18.7c1.3-10.6,0.4-14.9-1.8-21.3s-4-13-4-16.6c0.1-3.7,0.7-16,14-18.9C63-1.6,73,10.6,73.8,17.7s-1,21-2,25c-1.1,4.1-2,18.3,4.3,28.5c3.4,6.6,9.6,8.7,15.7,9.5c12.6,1.5,12.7,0.5,20.3,4.9c0,0,14-2.2,20.3-1c6.3,1.2,12.8,7.7,12.5,15.1c-0.1,4.3-1.8,8.3-4.8,11.4c1.8,3.7,2.3,7.8,1.2,11.8c-1.8,7.2-5.8,9.5-5.8,9.5s6.9,12.4-4.6,23.8c0,0,2.2,16.2-8.1,20.9c-9.1,4.2-21.1,0.1-28.6-5.6c0,0-8.3,8.2-25.3,7.3s-34.2-11.9-40.3-21.8c0,0-4.9-7.2-6.7-12.9C18,142.6,9,138.8,3.2,126.6z"
      />
      <g class="hand__outlines">
        <path
          class="hand__outline"
          d="M38,121.3c14.6,1.5,29.4-4.8,33.7-18.2c0.2-0.5-0.1-1.1-0.7-1.3c-0.5-0.2-1.1,0.1-1.3,0.7c-4,12.4-17.9,18.1-31.5,16.7c-0.6-0.1-1.1,0.4-1.1,0.9C37,120.7,37.4,121.2,38,121.3L38,121.3z"
        />
        <path
          class="hand__outline"
          d="M21,144.3c2.6,7.4,9.3,18,14.8,22.4c8.5,6.9,22.1,12.7,35.2,13.2c8.8,0.4,17.4-1.7,23.9-7.5c0.4-0.4,0.5-1,0.1-1.5c-0.4-0.4-1-0.5-1.5-0.1c-6.2,5.5-14.2,7.3-22.4,7.1c-12.6-0.5-25.7-6.2-34-12.8c-5.3-4.2-11.7-14.4-14.1-21.5c-0.2-0.5-0.8-0.8-1.3-0.6C21.1,143.1,20.8,143.7,21,144.3z"
        />
        <path
          class="hand__outline"
          d="M106.9,108.7c-3.7-3.3-5-7.1-4.7-10.6c0.5-5.3,5.3-11.5,9.9-13.3c5-2,10.7-2.2,16.5-1.9c6.1,0.3,12,2.6,15.6,9.6c3.3,6.3,1.6,14.7-3.2,19c-3,2.7-10.2,4-15.6,3.5C117.6,114.3,109.9,111.5,106.9,108.7z M142.4,93.5c-3.3-6.3-8.4-8.2-13.9-8.5c-5.6-0.3-11.8-0.1-15.6,1.7c-4.9,2.4-8.3,7.1-8.7,11.6c-0.3,3,0.9,6.1,4,8.8c3.7,3,10,5.2,17.5,5.8c4.9,0.4,10.2,0.4,13.9-2.9C142.7,107.3,146.5,102,142.4,93.5z"
        />
        <path
          class="hand__outline"
          d="M104.3,128.8c2.3,2.4,7.5,4.9,13.4,5.9c5.9,1.1,12.2,0.6,17.1-3.1l0,0c6-4.5,7.5-13.1,4.7-20.4c-0.2-0.5,0-1.1,0.6-1.3c0.5-0.2,1.1,0,1.3,0.6c3.3,8,1.4,17.7-5.3,22.7l0,0l0,0l0,0c-5.3,4.1-12.3,4.6-18.7,3.5c-6.4-1.1-12-4-14.5-6.6l0,0c-7-7.8-3.6-17.6,4.3-23.1c0.5-0.3,1.1-0.2,1.4,0.3c0.3,0.5,0.2,1.1-0.3,1.4C101.4,113.6,98.2,122,104.3,128.8z"
        />
        <path
          class="hand__outline"
          d="M130.4,155.1c6.2-4.6,7.6-15.1,4.7-21.9c-0.2-0.5,0-1.1,0.5-1.4c0.5-0.2,1.1,0,1.4,0.5c3.4,7.6,1.6,19.5-5.4,24.5h-0.1l-0.1,0.1c-6.8,3.6-17.1,3-25,0.3c-5.5-1.8-9.7-4.6-11.2-7.5v-0.1c-3-8.3,0.3-17.1,7.8-20.9c0.5-0.3,1.1,0,1.4,0.5c0.3,0.5,0,1.1-0.5,1.4c-6.6,3.3-9.4,10.9-6.9,18.3c1.4,2.6,5.3,4.9,10,6.5C114.5,157.7,124.2,158.4,130.4,155.1z"
        />
        <path
          class="hand__outline"
          d="M94.9,170.5c3.5,3.2,9.5,5.7,15.1,6.7c3.7,0.7,7.3,0.8,9.6,0c10.7-3.4,11.2-13.1,10.3-21.2c-0.1-0.6,0.4-1.1,0.9-1.1c0.6-0.1,1.1,0.4,1.1,0.9c0.9,9,0.2,19.6-11.8,23.3c-2.7,0.8-6.6,0.8-10.6,0c-6.1-1.1-12.5-3.8-16.2-7.3l-0.2-0.2c-4.9-8-4.5-17.2,2.3-22.8c0.4-0.4,1.1-0.3,1.5,0.1s0.3,1.1-0.1,1.5C90.9,155.4,90.7,163.5,94.9,170.5z"
        />
        <path
          class="hand__outline"
          d="M99.6,92.5c-3.1,4.6-4.1,10.3-2.7,15.7c0.2,0.6,0.7,0.9,1.3,0.7c0.6-0.2,0.9-0.7,0.7-1.3c-1.3-4.8-0.4-9.9,2.4-14c0.3-0.5,0.2-1.1-0.3-1.4C100.5,91.9,99.9,92,99.6,92.5z"
        />
        <path
          class="hand__outline"
          d="M97.7,111.7c-1.8,1.9-3,4.3-3.4,6.9c-0.8,3.1-0.3,6.3,1.4,8.9c0.3,0.5,1,0.6,1.4,0.3c0.5-0.3,0.6-1,0.3-1.4c-1.4-2.2-1.7-4.9-1.1-7.3c0.3-2.1,1.3-4.1,2.8-5.7c0.4-0.4,0.5-1,0.1-1.5C98.8,111.4,98.1,111.3,97.7,111.7z"
        />
        <path
          class="hand__outline"
          d="M93.8,132.5c-4,3.5-5.5,9.1-3.8,14.1c0.2,0.5,0.8,0.8,1.3,0.6c0.5-0.2,0.8-0.8,0.6-1.3c-1.5-4.3-0.2-8.9,3.2-11.9c0.4-0.4,0.5-1,0.1-1.5C94.9,132.2,94.3,132.1,93.8,132.5z"
        />
        <path
          class="hand__outline"
          d="M89.1,151.8c-3.4,4.4-5.3,10.7-2.1,16c0.3,0.5,0.9,0.6,1.4,0.3c0.5-0.3,0.6-0.9,0.3-1.4c-2.7-4.5-0.9-9.9,1.9-13.6c0.3-0.5,0.3-1.1-0.2-1.5C90.1,151.3,89.5,151.3,89.1,151.8z"
        />
        <path
          class="hand__outline"
          d="M136.7,93.9c1.4,2.8,1.2,6.6,0.4,9.5c-0.1,0.6,0.2,1.1,0.7,1.3c0.6,0.1,1.1-0.2,1.3-0.7c0.9-3.4,1-7.7-0.6-10.9c-0.3-0.5-0.9-0.7-1.4-0.5C136.7,92.7,136.5,93.4,136.7,93.9z"
        />
        <path
          class="hand__outline"
          d="M135.2,117.9c0.6,2.9,0.1,5.9-1.2,8.5c-0.2,0.5,0,1.1,0.5,1.4c0.5,0.2,1.1,0,1.4-0.5c1.5-3.1,2-6.6,1.4-9.8c-0.1-0.6-0.7-0.9-1.2-0.8C135.5,116.8,135.1,117.3,135.2,117.9z"
        />
        <path
          class="hand__outline"
          d="M131.8,140.1c0.2,2.8,0.3,5.7-1.2,8.1c-0.3,0.5-0.1,1.1,0.4,1.4c0.5,0.3,1.1,0.1,1.4-0.4c1.7-2.9,1.7-6.2,1.5-9.3c0-0.6-0.5-1-1.1-1C132.2,139,131.8,139.5,131.8,140.1z"
        />
        <path
          class="hand__outline"
          d="M125.2,162.2c-0.2,2.9-1.6,5.6-3.7,7.5c-0.4,0.4-0.5,1-0.1,1.5c0.4,0.4,1,0.5,1.5,0.1c2.5-2.3,4.1-5.5,4.4-8.8c0.1-0.6-0.3-1.1-0.9-1.1C125.7,161.3,125.2,161.7,125.2,162.2z"
        />
        <path
          class="hand__outline"
          d="M53.3,44.4c4.7,3.4,10.8,4.8,16.2,2c0.5-0.3,0.7-0.9,0.5-1.4c-0.3-0.5-0.9-0.7-1.4-0.5c-4.7,2.4-9.9,1.1-14-1.9c-0.5-0.3-1.1-0.2-1.4,0.2C52.7,43.5,52.8,44.1,53.3,44.4z"
        />
        <path
          class="hand__outline"
          d="M17.4,85.3c6.8-5.3,15.1-10.4,19.5-18.2c2.8-5,3.7-11,3.8-16.7c0-7-3.2-14.5-5-21.9c-2-7.7-2.5-15.4,3.1-22c3.2-3.7,7.6-6,12.5-6.4c3.3-0.3,6.7,0.2,9.7,1.5c11.4,4.6,14.4,12.8,14.5,22c0,8.9-2.8,18.9-3.1,27.3c-0.2,6.4,1.1,12.7,4,18.5c3.9,7.6,9.8,9.3,16.4,10.1c7,0.9,14.5,0.9,20.7,5.2c0.5,0.3,0.6,1,0.3,1.4c-0.3,0.5-1,0.6-1.4,0.3c-5.9-4.1-13.1-4-19.8-4.8c-7.2-0.9-13.7-2.9-18-11.3c-3-6.1-4.4-12.7-4.2-19.5c0.3-8.4,3.1-18.4,3.1-27.2c0-8.4-2.8-16-13.2-20.2c-2.8-1.2-5.8-1.6-8.8-1.3c-4.3,0.3-8.3,2.4-11.1,5.7c-5.1,6.2-4.5,13.1-2.7,20.3c1.9,7.5,5.2,15.3,5.1,22.4c0,6.1-1,12.4-4,17.7c-4.5,7.9-13,13.3-20,18.8h-0.1v0.1C9,93.7,6.1,100,4,105.4c-5.6,14,2.1,39.3,28.3,38.2c0.6,0,1.1,0.4,1.1,1s-0.4,1.1-1,1.1c-28.1,1.2-36.3-26-30.3-41C4.2,98.9,7.3,92.3,17.4,85.3z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    isOpen: Boolean,
  },
};
</script>

<template>
  <div>
    <BaseScreen name="demosurvey" class="demosurvey">
      <div class="demosurvey__body">
        <h2 class="demosurvey__title">{{ title }}</h2>
        <BaseButton v-if="why_content" is-link @click="showModal('demowhy')">{{
          why_button || 'Why'
        }}</BaseButton>

        <div
          class="demosurvey__buttons has-layout-stacked"
          style="margin-top: 30px"
        >
          <BaseButton is-tertiary data-cy="willShare" @click="startSurvey">{{
            consent_label
          }}</BaseButton>
          <BaseButton is-tertiary data-cy="willNotShare" @click="skipSurvey">{{
            abstain_label
          }}</BaseButton>
        </div>
      </div>
    </BaseScreen>

    <DemographicQuestionScreen
      v-for="(question, index) in questions"
      :key="question.id"
      v-bind="question"
      :heading="question_heading"
      :index="index"
      :total="questions.length"
      @next="nextQuestion"
    />

    <Portal to="demosurvey">
      <BaseModal
        v-if="why_content"
        name="demowhy"
        is-mini
        :content="why_content"
      />
    </Portal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: true,
    },
    why_button: String,
    why_content: String,
    consent_label: {
      type: String,
      required: true,
    },
    abstain_label: {
      type: String,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
    question_heading: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentQuestion: null,
    };
  },
  methods: {
    ...mapMutations(['goToScreen']),

    goToQuestion(index) {
      if (typeof index === 'number' && index < this.questions.length) {
        this.currentQuestion = index;
        this.goToScreen(this.questions[index].id);
      } else {
        this.currentQuestion = null;
        this.$emit('done');
      }
    },
    nextQuestion() {
      this.goToQuestion(this.currentQuestion + 1);
    },

    startSurvey() {
      this.$log.demographic({
        question_name: 'consent',
        answers: ['yes'],
      });
      this.goToQuestion(0);
    },
    skipSurvey() {
      this.$log.demographic({
        question_name: 'consent',
        answers: ['no'],
      });
      this.$emit('done');
    },
  },
};
</script>

<template>
  <svg
    :class="{
      'timer-shape': true,
      'is-animating': animate,
    }"
    :viewBox="`0 0 ${width} ${height}`"
    :width="width"
    :height="height"
  >
    <mask class="timer-shape__mask" :id="uid">
      <path
        v-if="isCloud"
        d="M48.9,59.2c4.8-32.3,34.8-54.6,67-49.8c12.7,1.9,24.4,7.8,33.4,16.9c40.6-11,82.4,13.1,93.4,53.7c11,40.6-13.1,82.4-53.7,93.4c-4.2,1.1-8.4,1.9-12.7,2.3c-19.3,25.9-56,31.2-81.8,11.9c-2.3-1.7-4.4-3.6-6.5-5.6c-35.2,6.8-69.2-16.1-76.1-51.3C6.1,101.2,21.5,71.6,48.9,59.2z"
      />
      <circle v-else r="90" cx="100" cy="100" />
    </mask>
    <g class="timer-shape__outline">
      <path
        v-if="isCloud"
        d="M44.1,56.5c5-34.2,36.9-57.9,71.1-52.8c13.4,2,25.9,8.3,35.4,17.9c43.1-11.6,87.4,13.9,99,57c11.6,43.1-13.9,87.4-57,99c-4.4,1.2-8.8,2-13.4,2.4c-20.5,27.5-59.3,33.1-86.8,12.6c-2.4-1.8-4.7-3.8-6.8-5.9c-37.3,7.3-73.4-17.1-80.7-54.4C-1.2,101,15,69.7,44.1,56.5z"
      />
      <circle v-else r="98" cx="100" cy="100" />
    </g>
    <g :mask="`url(#${uid})`">
      <TimerShapeFill
        v-if="duration"
        class="timer-shape__timer"
        :fill="timerFill"
        :fill-opacity="timerOpacity"
        :width="width"
        :height="height"
        :duration="duration * 1000"
        :start="start"
        :paused="paused"
        :delay="delay * 1000"
        @done="$emit('expire')"
      />
      <rect
        v-else
        x="0"
        y="0"
        :width="width"
        :height="height"
        :fill="altFill"
        :fill-opacity="altOpacity"
      />
    </g>
  </svg>
</template>

<script>
const CONFIG = {
  circle: {
    width: 200,
    height: 200,
    timerFill: 'black',
    timerOpacity: 0.05,
    altFill: 'none',
    altOpacity: 0,
  },
  cloud: {
    width: 256,
    height: 208,
    timerFill: 'white',
    timerOpacity: 1,
    altFill: 'white',
    altOpacity: 1,
  },
};

export default {
  props: {
    type: {
      type: String,
      default: 'circle',
    },
    duration: {
      type: Number,
      default: 0,
    },
    delay: {
      type: Number,
      default: 0,
    },
    start: Boolean,
    paused: Boolean,
    animate: Boolean,
  },
  data() {
    return {
      uid: 'v' + Date.now(),
      isCloud: this.type === 'cloud',
      ...CONFIG[this.type],
    };
  },
};
</script>

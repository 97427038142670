<template>
  <div
    :class="{
      'level-card': true,
      ['is-level-' + number]: true,
      'is-unlocked': isUnlocked,
      'is-latest': isLatest,
    }"
  >
    <StarGraphic :number="number" class="level-card__star" />
    <div class="level-card__poster">
      <div
        class="level-card__poster__image"
        :style="{
          'background-image': `url(${$url(imageUrl)})`,
        }"
      />
    </div>
    <div class="level-card__info">
      <h2 class="level-card__title">{{ name }}</h2>

      <BaseButton
        v-if="isUnlocked"
        name="download"
        :href="$url(downloadUrl)"
        download
        :location="name"
        is-primary
        is-rounded
        class="level-card__download"
      >
        {{ $l10n('download') }}
        <BaseIcon type="download" />
      </BaseButton>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    downloadUrl: {
      type: String,
      required: true,
    },
    isUnlocked: Boolean,
    isLatest: Boolean,
  },
};
</script>

<template>
  <BaseButton
    v-if="languages.length > 1"
    name="languages"
    location="main"
    class="langtoggle"
    is-quaternary
    @click="showModal('languages')"
  >
    <BaseIcon type="globe" />
    {{ currentLanguage.slug }}

    <Portal to="home">
      <BaseModal name="languages" is-mini>
        <div class="language-picker">
          <h2>{{ $l10n('select-language') }}</h2>

          <a
            v-for="language in languages"
            :key="language.slug"
            :class="{
              'language-picker__option': true,
              'is-current': currentLanguage === language,
            }"
            :href="getUrl(language.slug)"
            :hreflang="language.slug"
            >{{ language.name }}</a
          >
        </div>
      </BaseModal>
    </Portal>
  </BaseButton>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState(['id', 'baseURL', 'languages']),
    ...mapGetters({
      currentLanguage: 'getLanguage',
    }),
  },
  methods: {
    getUrl(language) {
      return `${this.baseURL}survey-${this.id}-${language}.html`;
    },
  },
};
</script>

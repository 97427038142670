<template functional>
  <svg :class="['emote', 'emote--' + props.face]" viewBox="0 0 76 76">
    <circle class="emote__backfill" fill="transparent" cx="38" cy="38" r="38" />
    <circle
      class="emote__fill"
      :fill="props.fill"
      stroke="#4E1F7D"
      stroke-width="3"
      cx="38"
      cy="38"
      r="32"
    />
    <g class="emote__face" v-if="props.face === 'very-upset'">
      <path
        fill="#FFFFFF"
        d="M36.49,59.58L36.49,59.58c-4.8-4.83-4.77-12.63,0.06-17.43s12.63-4.77,17.43,0.06
			c0.28,0.29,0.55,0.59,0.81,0.9c2.01,2.46,2.99,5.6,2.73,8.76c-1.94-1.97-4.49-3.22-7.24-3.54c-6.75-0.82-12.89,3.98-13.73,10.73
			C36.52,59.23,36.5,59.41,36.49,59.58L36.49,59.58z"
      />
      <path
        fill="#4E1F7D"
        d="M23.15,26.05c3.77-1.52,8.06,0.3,9.58,4.07s-0.3,8.06-4.07,9.58s-8.06-0.3-9.58-4.07l0,0
			C17.56,31.86,19.38,27.57,23.15,26.05z"
      />
      <path
        fill="#4E1F7D"
        d="M38.14,18.63c3.77-1.52,8.06,0.3,9.58,4.07s-0.3,8.06-4.07,9.58s-8.06-0.3-9.58-4.07
			S34.37,20.16,38.14,18.63z"
      />
      <path
        fill="#4E1F7D"
        d="M46.17,44.64c-5.21-0.64-9.92,2.84-10.53,7.78c-0.24,1.93,0.2,3.8,1.12,5.39
			c1.4-6.08,7.19-10.24,13.51-9.47c1.02,0.12,2,0.37,2.94,0.73C51.76,46.72,49.22,45.01,46.17,44.64z"
      />
    </g>
    <g class="emote__face" v-if="props.face === 'upset'">
      <path
        fill="#4E1F7D"
        d="M31.85,20.74c2.32-0.12,4.31,1.66,4.43,3.99s-1.66,4.31-3.99,4.43c-2.32,0.12-4.31-1.66-4.43-3.99
			C27.74,22.85,29.52,20.86,31.85,20.74z"
      />
      <path
        fill="#4E1F7D"
        d="M46.21,19.99c2.32-0.12,4.31,1.66,4.43,3.99c0.12,2.32-1.66,4.31-3.99,4.43c-2.32,0.12-4.31-1.66-4.43-3.99
			C42.11,22.09,43.89,20.11,46.21,19.99z"
      />
      <path
        fill="#FFFFFF"
        d="M56.68,52.87c-0.51-9.4-8.32-16.74-17.73-16.66c-9.41-0.07-17.22,7.27-17.73,16.67
			c5.85-0.1,11.78-0.18,17.73-0.18C44.9,52.7,50.83,52.78,56.68,52.87z"
      />
      <path
        fill="#4E1F7D"
        d="M56.68,52.87c-0.51-9.4-8.32-16.74-17.73-16.66c-1.67-0.01-3.29,0.22-4.82,0.64
			c-4.54,2.93-7.55,8.01-7.55,13.81c0,0.73,0.06,1.44,0.15,2.14c4.05-0.06,8.12-0.1,12.22-0.1C44.9,52.7,50.83,52.78,56.68,52.87z"
      />
    </g>
    <g class="emote__face" v-if="props.face === 'neutral'">
      <path
        fill="#4E1F7D"
        d="M28.02,24.42c2.45,0.04,4.4,2.06,4.36,4.51s-2.06,4.4-4.51,4.36s-4.4-2.06-4.36-4.51l0,0
			C23.56,26.33,25.57,24.38,28.02,24.42z"
      />
      <path
        fill="#4E1F7D"
        d="M46.77,24.75c2.45,0.04,4.4,2.06,4.36,4.51s-2.06,4.4-4.51,4.36c-2.45-0.04-4.4-2.06-4.36-4.51l0,0
			C42.31,26.66,44.32,24.71,46.77,24.75z"
      />
      <path
        fill="#FFFFFF"
        d="M54.7,43.95c0-0.78-0.34-1.53-0.94-2.03c-0.65-0.56-1.47-0.87-2.33-0.88l-24.76-0.42
			c-0.86-0.02-1.69,0.26-2.36,0.8c-0.61,0.49-0.98,1.22-1.01,2l-0.04,2.19c0,0.78,0.34,1.53,0.94,2.03c0.65,0.56,1.47,0.87,2.33,0.87
			l24.76,0.42c0.86,0.02,1.69-0.26,2.36-0.8c0.61-0.49,0.98-1.22,1.01-2L54.7,43.95z"
      />
      <path
        fill="#4E1F7D"
        d="M26.53,48.52l24.77,0.42c0.86,0.02,1.69-0.26,2.36-0.8c0.48-0.38,0.81-0.92,0.94-1.51
			c-0.57-0.27-1.2-0.44-1.88-0.45l-27.19-0.46c-0.79-0.01-1.53,0.19-2.18,0.54c0.13,0.54,0.42,1.02,0.85,1.39
			C24.85,48.21,25.67,48.52,26.53,48.52z"
      />
    </g>
    <g class="emote__face" v-if="props.face === 'happy'">
      <path
        fill="#4E1F7D"
        d="M46.17,24.02c-2.46-0.39-4.78,1.29-5.16,3.76s1.29,4.78,3.76,5.16c2.47,0.38,4.78-1.29,5.16-3.76l0,0
			C50.31,26.72,48.63,24.41,46.17,24.02z"
      />
      <path
        fill="#4E1F7D"
        d="M30.52,23.96c-2.46-0.39-4.78,1.29-5.16,3.76s1.29,4.78,3.76,5.16c2.47,0.38,4.78-1.29,5.16-3.76l0,0
			C34.66,26.67,32.98,24.35,30.52,23.96z"
      />
      <path
        fill="#FFFFFF"
        d="M38.29,53.57c-6.18-0.21-11.07-3.73-10.93-7.85s5.27-7.29,11.45-7.07c6.18,0.21,11.07,3.73,10.93,7.85
			C49.59,50.62,44.47,53.79,38.29,53.57z"
      />
      <path
        fill="#4E1F7D"
        d="M47.05,51.13c-0.98-2.01-2.98-3.78-5.61-4.69c-4.52-1.56-9.17,0.03-10.38,3.55
			c-0.15,0.44-0.24,0.89-0.28,1.34c1.95,1.29,4.58,2.14,7.5,2.24C41.79,53.69,44.94,52.73,47.05,51.13z"
      />
    </g>
    <g class="emote__face" v-if="props.face === 'very-happy'">
      <path
        fill="#FFFFFF"
        d="M21.49,39.73c-2.21,7.95,2.44,16.18,10.39,18.39c7.86,2.18,16.01-2.34,18.33-10.16
			c-4.39-2.45-9.04-4.41-13.87-5.84C31.5,40.78,26.52,39.98,21.49,39.73z"
      />
      <path
        fill="#4E1F7D"
        d="M30.4,18.99c2.33,0.58,3.74,2.94,3.16,5.26c-0.58,2.33-2.94,3.74-5.26,3.16s-3.74-2.94-3.16-5.26l0,0
			C25.72,19.83,28.08,18.41,30.4,18.99z"
      />
      <path
        fill="#4E1F7D"
        d="M53.45,25.95c2.33,0.58,3.74,2.94,3.16,5.26c-0.58,2.33-2.94,3.74-5.26,3.16
			c-2.33-0.58-3.74-2.94-3.16-5.26l0,0C48.77,26.78,51.12,25.37,53.45,25.95z"
      />
      <path
        fill="#4E1F7D"
        d="M31.74,52.93c-2.45-0.39-4.85-0.1-7.02,0.71c1.85,2.08,4.29,3.68,7.17,4.48c2.9,0.8,5.83,0.69,8.5-0.15
			C38.3,55.37,35.29,53.49,31.74,52.93z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    face: String,
    fill: String,
  },
};
</script>

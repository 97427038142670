import Vue from 'vue';
import Vuex from 'vuex';
import { Howler } from 'howler';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    id: 0,
    language: document.documentElement.lang || 'en',
    languages: [],

    answers: [],
    score: 0,

    currentScreen: '',
    activeModals: [],

    baseURL: '/',
    useAudio: false,

    isMobileLandscape: false,

    session: '',
  },
  getters: {
    getLanguage(state) {
      return state.languages.find(entry => {
        return entry.slug === state.language;
      });
    },

    getAnswer(state) {
      return question => {
        const entry = state.answers.find(entry => entry.question === question);

        return entry ? entry.answer : null;
      };
    },
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
    },

    saveAnswer(state, entry) {
      state.answers = [...state.answers, entry];
    },

    incrementScore(state) {
      state.score++;
    },
    decrementScore(state) {
      state.score--;
    },
    changeScore(state, result) {
      state.score = Math.max(0, state.score + (result ? 1 : -1));
    },

    goToScreen(state, name) {
      state.currentScreen = name;
    },

    showModal(state, name) {
      if (!state.activeModals.includes(name)) {
        state.activeModals.push(name);
      }
    },
    hideModal(state, name) {
      const activeModals = [...state.activeModals];
      const index = activeModals.indexOf(name);
      if (index >= 0) {
        activeModals.splice(index, 1);
        state.activeModals = activeModals;
      }
    },

    enableAudio(state) {
      state.useAudio = true;
      Howler.volume(1);
    },
    disableAudio(state) {
      state.useAudio = false;
      Howler.volume(0);
    },
    toggleAudio(state) {
      state.useAudio = !state.useAudio;
      Howler.volume(state.useAudio ? 1 : 0);
    },

    setMobileLandscape(state, value) {
      state.isMobileLandscape = value;
    },

    setSession(state, value) {
      state.session = value;
    },

    reset(state) {
      state.answers = [];
      state.score = 0;
      state.activeModals = [];
      state.currentScreen = '';
    },
  },
});

import setup from './framework/setup';

import App from './framework/components/App';
import BaseButton from './framework/components/BaseButton';
import BaseDraggable from './framework/components/BaseDraggable';
import BaseDraggableHand from './framework/components/BaseDraggableHand';
import BaseModal from './framework/components/BaseModal';
import BaseScreen from './framework/components/BaseScreen';
import BinaryDraggableQuestion from './framework/components/BinaryDraggableQuestion';
import ButtonsQuestion from './framework/components/ButtonsQuestion';
import BylineButton from './framework/components/BylineButton';
import DemographicQuestionScreen from './framework/components/DemographicQuestionScreen';
import GameScreen from './framework/components/GameScreen';
import HandSliderQuestion from './framework/components/HandSliderQuestion';
import InterruptModal from './framework/components/InterruptModal';
import InterruptQuestionScreen from './framework/components/InterruptQuestionScreen';
import IntroQuestionScreen from './framework/components/IntroQuestionScreen';
import LanguagePicker from './framework/components/LanguagePicker';
import LevelCard from './framework/components/LevelCard';
import MainQuestionScreen from './framework/components/MainQuestionScreen';
import ProgressStarIcon from './framework/components/ProgressStarIcon';
import QuaternaryDraggableQuestion from './framework/components/QuaternaryDraggableQuestion';
import RecoveryQuestionScreen from './framework/components/RecoveryQuestionScreen';
import ScreenReaderText from './framework/components/ScreenReaderText';
import ShareButtonGroup from './framework/components/ShareButtonGroup';
import SliderQuestion from './framework/components/SliderQuestion';
import StarGraphic from './framework/components/StarGraphic';
import TheAppToolbar from './framework/components/TheAppToolbar';
import TheDemographicSurvey from './framework/components/TheDemographicSurvey';
import TheEndScreen from './framework/components/TheEndScreen';
import TheGameStatus from './framework/components/TheGameStatus';
import TheIntroSurvey from './framework/components/TheIntroSurvey';
import TheLandscapeNotice from './framework/components/TheLandscapeNotice';
import TheLevelScreen from './framework/components/TheLevelScreen';
import TheLevelsModal from './framework/components/TheLevelsModal';
import TheRecoveryNoticeScreen from './framework/components/TheRecoveryNoticeScreen';
import TheShareScreen from './framework/components/TheShareScreen';
import TheSoundButton from './framework/components/TheSoundButton';
import TimerShapeFill from './framework/components/TimerShapeFill';

import BaseIcon from './components/BaseIcon';
import HandGraphic from './components/HandGraphic';
import EmoteGraphic from './components/EmoteGraphic';
import TheAboutModal from './components/TheAboutModal';
import TheHomeScreen from './components/TheHomeScreen';
import ThumbEmoteGraphic from './components/ThumbEmoteGraphic';
import TimerShape from './components/TimerShape';

import './assets/styles/theme.scss';
// eslint-disable-next-line no-unused-vars
import globalStyles from './globalStyles';

const BASE_URL = process.env.VUE_APP_BASE_URL || process.env.BASE_URL;

setup(
  {
    music_source: BASE_URL + 'music.mp3',
    soundEffects: {
      source: BASE_URL + 'effects.mp3',
      sprite: [
        ['drop', '0:00.000', '0:00.999'],
        ['pickup', '0:01.000', '0:01.999'],
        ['level-up', '0:02.000', '0:04.999'],
        ['level-down', '0:05.000', '0:07.999'],
        ['correct', '0:08.000', '0:11.999'],
        ['click', '0:12.000', '0:12.999'],
        ['incorrect', '0:13.000', '0:13.999'],
      ],
    },
  },
  {
    App,
    BaseButton,
    BaseDraggable,
    BaseDraggableHand,
    BaseModal,
    BaseScreen,
    BinaryDraggableQuestion,
    ButtonsQuestion,
    BylineButton,
    DemographicQuestionScreen,
    GameScreen,
    HandSliderQuestion,
    InterruptModal,
    InterruptQuestionScreen,
    IntroQuestionScreen,
    LanguagePicker,
    LevelCard,
    MainQuestionScreen,
    ProgressStarIcon,
    QuaternaryDraggableQuestion,
    RecoveryQuestionScreen,
    ScreenReaderText,
    ShareButtonGroup,
    SliderQuestion,
    StarGraphic,
    TheAppToolbar,
    TheDemographicSurvey,
    TheEndScreen,
    TheGameStatus,
    TheIntroSurvey,
    TheLandscapeNotice,
    TheLevelScreen,
    TheLevelsModal,
    TheRecoveryNoticeScreen,
    TheShareScreen,
    TheSoundButton,
    TimerShapeFill,

    BaseIcon,
    EmoteGraphic,
    HandGraphic,
    TheAboutModal,
    TheHomeScreen,
    ThumbEmoteGraphic,
    TimerShape,
  }
);

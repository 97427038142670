export default {
  headphones: {
    size: 16,
    paths: {
      fill: 'M8,0.2c-4.4,0-8,3.6-8,8v4.1l0,0c0,2,1.6,3.5,3.6,3.5H4c0.4,0,0.7-0.3,0.7-0.7V9.4C4.8,9,4.4,8.6,4,8.6H3.6c-0.8,0-1.5,0.2-2.1,0.7V8.2c0-3.6,2.9-6.5,6.5-6.5c3.6,0,6.5,2.9,6.5,6.5v1.1c-0.6-0.4-1.3-0.7-2.1-0.7H12c-0.4,0-0.7,0.3-0.7,0.7V15c0,0.4,0.3,0.7,0.7,0.7h0.5c2,0,3.5-1.6,3.6-3.5l0,0V8.2C16,3.8,12.5,0.2,8,0.2C8,0.2,8,0.2,8,0.2z',
    },
  },
  gear: {
    size: 25,
    paths: {
      fill: 'M14.2,0.4c-1.1-0.2-2.3-0.2-3.4,0c-0.5,0.9-0.8,1.9-0.8,3C8.8,3.7,7.7,4.2,6.7,4.9c-0.8-0.6-1.8-1-2.8-1.2C3,4.5,2.3,5.4,1.7,6.4c0.4,1,1,1.8,1.8,2.5C3,10,2.8,11.2,2.7,12.5c-1,0.3-1.9,0.8-2.7,1.5c0.1,1.1,0.4,2.2,0.7,3.3c1,0.3,2.1,0.4,3.1,0.2c0.6,1.1,1.4,2.1,2.3,2.9c-0.4,1-0.6,2-0.5,3c0.9,0.6,2,1.1,3,1.5c0.9-0.6,1.6-1.4,2-2.3c1.2,0.2,2.5,0.2,3.7,0c0.5,0.9,1.2,1.7,2,2.3c1.1-0.3,2.1-0.8,3-1.5c0.1-1-0.1-2.1-0.5-3c0.9-0.8,1.7-1.8,2.3-2.9c1,0.2,2.1,0.2,3.1-0.2c0.4-1.1,0.6-2.2,0.7-3.3c-0.8-0.7-1.7-1.2-2.7-1.5c0-1.2-0.3-2.4-0.8-3.6c0.8-0.7,1.4-1.5,1.8-2.5c-0.6-1-1.3-1.9-2.1-2.6c-1,0.2-2,0.6-2.8,1.2c-1-0.7-2.1-1.3-3.3-1.6C15,2.3,14.7,1.3,14.2,0.4z M12.5,8.8c2.2,0,4,1.8,4,4c0,2.2-1.8,4-4,4c-2.2,0-4-1.8-4-4C8.5,10.6,10.3,8.8,12.5,8.8z',
    },
  },
  left: {
    width: 21,
    height: 24,
    paths: {
      fill: 'M1.5,14.6c-1.4-0.8-1.9-2.7-1.1-4.1C0.7,10,1,9.7,1.5,9.4l15-8.8c1.4-0.8,3.3-0.4,4.1,1.1c0.3,0.5,0.4,1,0.4,1.5v17.5c0,1.7-1.3,3-3,3c-0.5,0-1.1-0.1-1.5-0.4L1.5,14.6z',
    },
  },
  right: {
    width: 21,
    height: 24,
    paths: {
      fill: 'M19.5,9.4c1.4,0.8,1.9,2.7,1.1,4.1c-0.3,0.4-0.6,0.8-1.1,1.1l-15,8.8c-1.4,0.8-3.3,0.4-4.1-1.1c-0.3-0.5-0.4-1-0.4-1.5V3.2c0-1.7,1.3-3,3-3c0.5,0,1.1,0.1,1.5,0.4L19.5,9.4z',
    },
  },
  close: {
    size: 38,
    paths: {
      fill: 'M24.6,19l8.3-8.3c1.5-1.5,1.5-4,0-5.6c-1.5-1.5-4-1.5-5.6,0L19,13.4l-8.3-8.3c-1.5-1.5-4-1.5-5.6,0C4.4,5.9,4,6.9,4,7.9c0,1,0.4,2,1.2,2.8l8.3,8.3l-8.3,8.3c-1.5,1.5-1.5,4,0,5.6C5.9,33.6,6.9,34,7.9,34c1,0,2-0.4,2.8-1.2l8.3-8.3l8.3,8.3c1.5,1.5,4,1.5,5.6,0c1.5-1.5,1.5-4,0-5.6L24.6,19z',
    },
  },
  next: {
    title: 'Next',
    size: 38,
    paths: {
      fill: 'M11.5,38c-1.2,0-2.3-0.5-3.1-1.5C7,34.8,7.3,32.3,9,31l15.1-12L9.1,7c-1.7-1.4-2-3.8-0.6-5.5s3.8-2,5.5-0.6L32.9,16c0.9,0.7,1.5,1.9,1.5,3.1s-0.5,2.3-1.5,3.1L14,37.1C13.3,37.7,12.4,38,11.5,38z',
    },
  },
  starSmall: {
    size: 12,
    paths: {
      fill: 'M8.7,11.5c-0.2,0-0.3,0-0.5-0.1l-2.2-1l-2.2,1c-0.1,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.5-0.1-0.7-0.2c-0.3-0.2-0.5-0.6-0.4-1l0.2-2.6L0.8,5.7C0.5,5.4,0.4,5,0.6,4.6C0.7,4.2,1,3.9,1.4,3.9l2.4-0.5L5,1.1c0.2-0.4,0.6-0.6,1-0.6s0.8,0.2,1,0.6l1.2,2.2l2.4,0.5c0.4,0.1,0.7,0.4,0.8,0.8c0.1,0.4,0,0.8-0.2,1.1L9.6,7.7l0.2,2.6c0,0.4-0.1,0.8-0.4,1C9.2,11.4,8.9,11.5,8.7,11.5z',
      stroke:
        'M6,1C5.8,1,5.6,1.1,5.5,1.3c-0.6,1-1.4,2.4-1.4,2.4S2.6,4.1,1.5,4.4C1.3,4.4,1.1,4.6,1,4.8C1,5,1,5.2,1.2,5.4C1.9,6.3,3,7.5,3,7.5s-0.2,1.6-0.3,2.8c0,0.2,0.1,0.4,0.2,0.6C3,10.9,3.2,11,3.3,11c0.1,0,0.2,0,0.2-0.1C4.6,10.5,6,9.8,6,9.8s1.4,0.7,2.5,1.1C8.5,11,8.6,11,8.7,11c0.1,0,0.3,0,0.4-0.1c0.2-0.1,0.3-0.4,0.2-0.6C9.2,9.1,9,7.5,9,7.5s1.1-1.2,1.8-2.1C11,5.2,11,5,11,4.8c-0.1-0.2-0.2-0.4-0.5-0.4C9.4,4.1,7.9,3.8,7.9,3.8S7.1,2.3,6.5,1.3C6.4,1.1,6.2,1,6,1 M6,0c0.6,0,1.1,0.3,1.4,0.8l1.1,2l2.2,0.5c0.6,0.1,1,0.6,1.2,1.1c0.2,0.5,0,1.2-0.3,1.6l-1.5,1.8l0.2,2.4c0.1,0.6-0.2,1.1-0.6,1.5c-0.3,0.2-0.6,0.3-1,0.3c-0.2,0-0.5,0-0.7-0.1l-2-0.9l-2,0.9C3.8,12,3.5,12,3.3,12c-0.3,0-0.7-0.1-1-0.3c-0.5-0.3-0.7-0.9-0.6-1.5l0.2-2.4L0.4,6.1C0,5.6-0.1,5,0.1,4.5c0.2-0.6,0.6-1,1.2-1.1l2.2-0.5l1.1-2C4.9,0.3,5.4,0,6,0z',
    },
  },
  starLarge: {
    size: 70,
    paths: {
      fill: 'M54,69c-0.5,0-0.9-0.1-1.3-0.3l-17.3-7.9L35,60.6l-0.4,0.2l-17.3,7.9C16.9,68.9,16.5,69,16,69c-0.7,0-1.3-0.2-1.9-0.6c-1-0.7-1.5-1.9-1.3-3.2l1.9-19.6l0-0.4l-0.3-0.3L1.9,30.1c-0.8-0.9-1-2.2-0.7-3.4c0.4-1.2,1.3-2,2.4-2.3L22,20.2l0.4-0.1l0.2-0.4l9.5-17C32.8,1.7,33.8,1,35,1c1.2,0,2.2,0.7,2.8,1.7l9.5,17l0.2,0.4l0.4,0.1l18.4,4.2c1.1,0.3,2.1,1.1,2.4,2.3c0.4,1.2,0.1,2.5-0.7,3.4L55.6,44.8l-0.3,0.3l0,0.4l1.9,19.6c0.1,1.2-0.4,2.4-1.3,3.2C55.3,68.8,54.7,69,54,69z',
      stroke:
        'M35,2c-0.8,0-1.5,0.5-1.9,1.2l-9.5,17L23.1,21l-0.9,0.2L3.8,25.4C3,25.6,2.4,26.2,2.1,27c-0.3,0.9-0.1,1.8,0.5,2.4l12.6,14.7l0.6,0.7l-0.1,0.9l-1.9,19.6c-0.1,0.9,0.3,1.8,1,2.3c0.4,0.3,0.8,0.4,1.3,0.4c0.3,0,0.6-0.1,0.9-0.2l17.3-7.9l0.8-0.4l0.8,0.4l17.3,7.9c0.3,0.1,0.6,0.2,0.9,0.2c0.5,0,0.9-0.2,1.3-0.4c0.7-0.5,1-1.4,1-2.3l-1.9-19.6l-0.1-0.9l0.6-0.7l12.6-14.7c0.6-0.7,0.7-1.6,0.5-2.4c-0.3-0.8-0.9-1.4-1.7-1.6l-18.4-4.2L46.9,21l-0.4-0.8l-9.5-17C36.5,2.5,35.8,2,35,2 M35,0c1.5,0,2.9,0.8,3.7,2.2c3.9,7,9.5,17,9.5,17s10.9,2.5,18.4,4.2c1.5,0.3,2.7,1.5,3.2,3c0.5,1.5,0.1,3.2-0.9,4.4c-5.2,6-12.6,14.7-12.6,14.7s1.1,11.5,1.9,19.6c0.2,1.6-0.5,3.1-1.7,4.1C55.8,69.7,54.9,70,54,70c-0.6,0-1.2-0.1-1.7-0.4C45.2,66.4,35,61.7,35,61.7s-10.2,4.7-17.3,7.9C17.2,69.9,16.6,70,16,70c-0.9,0-1.8-0.3-2.5-0.9c-1.2-0.9-1.9-2.5-1.7-4.1c0.8-8.1,1.9-19.6,1.9-19.6S6.3,36.8,1.1,30.8c-1-1.2-1.4-2.8-0.9-4.4c0.5-1.5,1.7-2.7,3.2-3c7.6-1.7,18.4-4.2,18.4-4.2s5.6-10,9.5-17C32.1,0.8,33.5,0,35,0z',
    },
  },
  download: {
    size: 38,
    paths: {
      arrow: 'M19.3,28.4L29.1,16h-6.8V3c0-3.9-5.9-3.9-5.9,0v13H9.5L19.3,28.4z',
      bar: 'M29,37.9H9c-2.2,0-4-1.8-4-4s1.8-4,4-4h20c2.2,0,4,1.8,4,4S31.2,37.9,29,37.9z',
    },
  },
  check: {
    width: 11,
    height: 9,
    paths: {
      fill: 'M4.1,8.6L0.4,4.8C0,4.4,0,3.8,0.4,3.4s1-0.4,1.4,0l2.3,2.3l5.1-5.1c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L4.1,8.6z',
    },
  },
  soundOn: {
    title: 'Mute audio',
    width: 17,
    height: 16,
    paths: {
      fill: 'M4.4,3H2.2C1.2,3,0.3,3.8,0.3,4.9c0,0,0,0,0,0v6.2c0,1.1,0.8,1.9,1.9,1.9c0,0,0,0,0,0h2.1V3z M13.9,0.4c-0.5-0.4-1.1-0.5-1.7-0.3L5.6,2.8v10.5l6.6,2.7c0.2,0.1,0.4,0.1,0.6,0.1c1.1,0,1.9-0.9,1.9-1.9l0,0V2C14.7,1.3,14.4,0.8,13.9,0.4z',
    },
  },
  soundOff: {
    title: 'Unmute audio',
    width: 28,
    height: 16,
    paths: {
      fill: 'M4.1,3H1.9C0.9,2.9,0,3.8,0,4.9c0,0,0,0,0,0v6.2c0,1.1,0.8,1.9,1.9,1.9c0,0,0,0,0,0h2.1V3z M26,13.6c-0.5,0.5-1.1,0.7-1.7,0.7c-0.6,0-1.3-0.2-1.7-0.7l-1.7-1.7l-1.7,1.7c-0.5,0.5-1.1,0.7-1.7,0.7s-1.3-0.2-1.7-0.7c-1-1-1-2.5,0-3.5l1.7-1.7l-1.7-1.7c-1-1-1-2.5,0-3.5c0.5-0.5,1.1-0.7,1.7-0.7s1.3,0.2,1.7,0.7l1.7,1.7l1.7-1.7c0.5-0.5,1.1-0.7,1.7-0.7c0.6,0,1.3,0.2,1.7,0.7c1,1,1,2.5,0,3.5l-1.7,1.7l1.7,1.7C27,11.1,27,12.6,26,13.6z M14.5,13.7V14c0,1.1-0.9,1.9-1.9,1.9c-0.2,0-0.4,0-0.6-0.1l-6.6-2.7V2.8l6.6-2.7c0.6-0.2,1.2-0.1,1.7,0.3c0.5,0.4,0.8,0.9,0.8,1.6V3c-0.8,1.1-0.8,2.7,0,3.8v3.1c-0.4,0.6-0.6,1.2-0.6,1.9C13.9,12.5,14.1,13.2,14.5,13.7z',
    },
  },
  globe: {
    size: 16,
    paths: {
      fill: 'M14.6,12c0.7-1.2,1.1-2.6,1.1-4l0,0c0-1.4-0.4-2.8-1.1-4v0C12.4,0.3,7.6-0.8,4,1.4C2.9,2.1,2.1,2.9,1.4,4v0c-1.5,2.5-1.5,5.6,0,8.1v0c2.2,3.6,7,4.8,10.6,2.5C13.1,13.9,13.9,13.1,14.6,12L14.6,12z M9,14.4c-0.1,0.1-0.3,0.3-0.5,0.3c-0.3,0.1-0.7,0.1-1,0c-0.4-0.2-0.7-0.4-0.9-0.8c-0.5-0.7-0.8-1.4-1-2.2c0.8,0,1.6-0.1,2.4-0.1c0.8,0,1.6,0,2.4,0.1c-0.1,0.4-0.3,0.8-0.4,1.2C9.7,13.5,9.4,14,9,14.4L9,14.4z M1.2,8.4h3.1c0,0.9,0.1,1.7,0.3,2.6c-0.9,0.1-1.7,0.2-2.5,0.3C1.5,10.4,1.2,9.4,1.2,8.4L1.2,8.4z M2,4.7C2.9,4.8,3.7,4.9,4.6,5C4.4,5.8,4.3,6.7,4.3,7.6H1.2C1.2,6.5,1.5,5.6,2,4.7L2,4.7z M7,1.6c0.1-0.1,0.3-0.3,0.5-0.3c0.3-0.1,0.7-0.1,1,0C8.8,1.4,9.1,1.7,9.4,2c0.5,0.7,0.8,1.4,1,2.2C9.6,4.2,8.8,4.3,8,4.3c-0.8,0-1.6,0-2.4-0.1C5.8,3.8,5.9,3.4,6.1,3C6.3,2.5,6.6,2,7,1.6L7,1.6z M14.8,7.6h-3.1c0-0.9-0.1-1.7-0.3-2.6c0.9-0.1,1.7-0.2,2.5-0.3C14.5,5.6,14.8,6.5,14.8,7.6L14.8,7.6z M5.4,11c-0.2-0.8-0.3-1.7-0.3-2.5h5.7c0,0.8-0.1,1.7-0.3,2.5c-0.9-0.1-1.7-0.1-2.6-0.1C7.1,10.9,6.3,10.9,5.4,11L5.4,11z M10.6,5c0.2,0.8,0.3,1.7,0.3,2.5H5.1c0-0.8,0.1-1.7,0.3-2.5C6.3,5.1,7.1,5.1,8,5.1C8.9,5.1,9.7,5.1,10.6,5L10.6,5z M11.7,8.4h3.1c-0.1,1-0.4,2-0.8,2.9c-0.8-0.1-1.7-0.2-2.5-0.3C11.6,10.2,11.7,9.3,11.7,8.4L11.7,8.4z M13.5,3.9C12.7,4,12,4.1,11.2,4.1c-0.1-0.5-0.3-1-0.5-1.5c-0.2-0.4-0.4-0.8-0.7-1.2C11.4,1.9,12.6,2.7,13.5,3.9z M3.2,3.2C3.9,2.4,4.9,1.8,6,1.4l0,0.1C5.4,2.3,5,3.2,4.8,4.1C4,4.1,3.3,4,2.5,3.9C2.7,3.6,2.9,3.4,3.2,3.2z M2.5,12.1C3.3,12,4,11.9,4.8,11.9c0.1,0.5,0.3,1,0.5,1.5c0.2,0.4,0.4,0.8,0.7,1.2C4.6,14.1,3.4,13.3,2.5,12.1L2.5,12.1z M12.8,12.8c-0.8,0.8-1.8,1.4-2.8,1.7l0-0.1c0.6-0.8,1-1.7,1.2-2.6c0.7,0.1,1.5,0.1,2.2,0.3C13.3,12.4,13.1,12.6,12.8,12.8L12.8,12.8z',
    },
  },
  twitter: {
    size: 50,
    paths: {
      fill: 'M20.7,38.6c11,0,17.1-9.1,17.1-17.1c0-0.3,0-0.5,0-0.8c1.2-0.8,2.2-1.9,3-3.1c-1.1,0.5-2.3,0.8-3.4,0.9c1.3-0.7,2.2-1.9,2.6-3.3c-1.2,0.7-2.5,1.2-3.8,1.5c-1.1-1.2-2.7-1.9-4.4-1.9c-3.3,0-6,2.7-6,6c0,0.5,0.1,0.9,0.2,1.4c-4.8-0.2-9.3-2.5-12.4-6.3c-0.5,0.9-0.8,2-0.8,3c0,2,1,3.9,2.7,5c-1,0-1.9-0.3-2.7-0.8c0,0,0,0.1,0,0.1c0,2.9,2,5.3,4.8,5.9c-0.5,0.1-1,0.2-1.6,0.2c-0.4,0-0.8,0-1.1-0.1c0.8,2.4,3,4.1,5.6,4.2C18.3,35.1,15.7,36,13,36c-0.5,0-1,0-1.4-0.1C14.3,37.6,17.5,38.6,20.7,38.6z',
    },
  },
  facebook: {
    size: 50,
    paths: {
      fill: 'M35.1,32.2l1.1-7.2H29v-4.7c0-2,1-3.9,4.2-3.9h3.3v-6.2c-1.9-0.3-3.9-0.5-5.8-0.5c-5.9,0-9.8,3.5-9.8,9.7V25h-6.6v7.2h6.6L20.9,50H29L29,32.2L35.1,32.2L35.1,32.2z',
    },
  },
  deviceRotate: {
    size: 64,
    paths: {
      fill: 'M29.2,2.8c-2.5-2.7-6.6-2.8-9.3-0.3L2.4,19.9c-2.5,2.7-2.3,6.8,0.3,9.3l32,32c1.3,1.3,3.1,2.1,5,2.1c1.6,0,3.2-0.6,4.4-1.8l17.4-17.4c2.5-2.7,2.3-6.8-0.3-9.3L29.2,2.8z M59.5,42.1L42,59.5c-1.5,1.3-3.8,1.2-5.1-0.3l-32-32c-1.5-1.3-1.6-3.6-0.3-5.1L22,4.6c0.6-0.6,1.4-0.9,2.3-0.9c1.1,0,2.1,0.5,2.9,1.2l32,32C60.6,38.3,60.8,40.6,59.5,42.1z M46,46.1c-0.9,0.9-0.9,2.4,0,3.3c0.9,0.9,2.4,0.9,3.3,0s0.9-2.4,0-3.3c0,0,0,0,0,0C48.4,45.2,46.9,45.2,46,46.1C46,46.1,46,46.1,46,46.1z M63,20.4c-0.7-0.4-1.6-0.1-2,0.6l-0.4,0.8C59.2,10.4,50,1.5,38.5,0.5c-0.8-0.1-1.5,0.5-1.6,1.3s0.5,1.5,1.3,1.6l0,0c10.1,0.9,18.2,8.7,19.4,18.8L57,21.8c-0.6-0.5-1.6-0.4-2.1,0.2c-0.5,0.6-0.4,1.6,0.2,2.1l3.9,3.3h0c0,0,0,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0s0,0,0,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0h0c0.1,0,0.2,0,0.3,0l0.1,0c0.1,0,0.2,0,0.3-0.1l0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0,0s0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0v0l2.3-4.4C64,21.7,63.8,20.8,63,20.4C63,20.4,63,20.4,63,20.4z M25.8,60.5C15.6,59.7,7.5,51.8,6.3,41.7L7,42.2c0.6,0.5,1.6,0.4,2.1-0.2c0.5-0.6,0.4-1.6-0.2-2.1l-3.9-3.3h0c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1h0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1l0,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1v0l-2.3,4.4c-0.4,0.7-0.1,1.6,0.6,2s1.6,0.1,2-0.6c0,0,0,0,0-0.1l0.4-0.8C4.8,53.6,14,62.5,25.5,63.5c0,0,0.1,0,0.1,0c0.8,0,1.5-0.7,1.5-1.5C27.1,61.2,26.5,60.6,25.8,60.5L25.8,60.5z',
    },
  },
  waves: {
    title: 'Waves',
    width: 10,
    height: 13,
    paths: {
      line1:
        'M1,0L1,0c0.6,0,1,0.4,1,1v11c0,0.6-0.4,1-1,1l0,0c-0.6,0-1-0.4-1-1V1C0,0.4,0.4,0,1,0z',
      line2:
        'M5,0L5,0c0.6,0,1,0.4,1,1v11c0,0.6-0.4,1-1,1l0,0c-0.6,0-1-0.4-1-1V1C4,0.4,4.4,0,5,0z',
      line3:
        'M9,0L9,0c0.6,0,1,0.4,1,1v11c0,0.6-0.4,1-1,1l0,0c-0.6,0-1-0.4-1-1V1C8,0.4,8.4,0,9,0z',
    },
  },
};
